import React, { useEffect, useState } from "react";
import "../assets/css/footer.css";
import { hideLoader, showLoader, isLangSk } from "../assets/js/helpers";
import { Helmet } from "react-helmet-async";
import '../assets/css/program.css'
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    MDBCollapse,
    MDBContainer,
    MDBListGroup,
    MDBListGroupItem,
    MDBTypography,
} from "mdb-react-ui-kit";

const VolebnyProgram = () => {
    const [collapse1, setCollapse1] = useState(false);
    const [collapse2, setCollapse2] = useState(false);
    const [collapse3, setCollapse3] = useState(false);
    const [collapse4, setCollapse4] = useState(false);
    const [collapse5, setCollapse5] = useState(false);
    const [collapse6, setCollapse6] = useState(false);
    const [collapse7, setCollapse7] = useState(false);
    const [collapse8, setCollapse8] = useState(false);
    const [t, i18n] = useTranslation()
    const [lang, setLang] = useState();

    const toggleCollapse1 = () => setCollapse1(!collapse1);
    const toggleCollapse2 = () => setCollapse2(!collapse2);
    const toggleCollapse3 = () => setCollapse3(!collapse3);
    const toggleCollapse4 = () => setCollapse4(!collapse4);
    const toggleCollapse5 = () => setCollapse5(!collapse5);
    const toggleCollapse6 = () => setCollapse6(!collapse6);
    const toggleCollapse7 = () => setCollapse7(!collapse7);
    const toggleCollapse8 = () => setCollapse8(!collapse8);

    useEffect(() => {
        showLoader();
        i18n.changeLanguage(localStorage.getItem("lang"))
        let lang = isLangSk();
        setLang(lang)
        setTimeout(hideLoader, 500);
    }, []);

    useEffect(() => {
        const handleBeforePrint = () => {
            // Toggle collapse state for specific elements
            toggleCollapse1();
            toggleCollapse2();
            toggleCollapse3();
            toggleCollapse4();
        };

        const printMediaQuery = window.matchMedia('print');
        printMediaQuery.addListener((e) => {
            if (e.matches) {
                handleBeforePrint();
            }
        });

        return () => {
            printMediaQuery.removeListener((e) => {
                if (e.matches) {
                    handleBeforePrint();
                }
            });
        };
    }, []);

    return (
        <div style={{ backgroundColor: "#0c141b" }}>


            <div className="container" style={{ marginTop: "120px", marginBottom: "7%" }}>
                <div className="info-background">
                    <Helmet>
                        <title>Volebný program | Spravodlivosť | Strana Spravodlivosť | Politická strana Spravodlivosť | Voľby 2023</title>
                        <meta
                            name="description"
                            content="Spravodlivosť | Strana Spravodlivosť | Politická strana Spravodlivosť | Voľby 2023"
                        ></meta>
                        <meta name="keywords" content="Spravodlivosť | Strana Spravodlivosť | Politická strana Spravodlivosť | Voľby 2023" />
                        <link rel="canonical" href={`/ochrana`}></link>
                    </Helmet>
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{ textAlign: "justify" }}>
                                <div id="text-id-1" >
                                    <div className="mb-4" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <h2 id="header-main-id-program" style={{ color: 'rgb(182, 182, 182)' }}>
                                            {/* {!lang ? t('electoralProgram.heading') : "Volebný program"} */}
                                            {!lang ? t('electoralProgram.heading') : "Volebný program pre Spravodlivosť"}
                                        </h2>
                                        <a
                                            id="pdf_download"
                                            style={{
                                                padding: "13px 15px 13px 15px",
                                                backgroundColor: "#1a242d",
                                                borderRadius: "13px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            href={
                                                !lang
                                                  ? `${process.env.PUBLIC_URL}/pdf/${localStorage.getItem("lang")}.pdf`
                                                  : `${process.env.PUBLIC_URL}/pdf/sk.pdf`
                                              }
                                            download
                                        >
                                            <p
                                                className="typewriter-text animate"
                                                style={{ fontSize: "15px", margin: "0", textAlign: "center" }}
                                            >
                                                 {!lang ? t("electoralProgram.downloadPdf") : "Stiahnite si program"}
                                            </p>
                                        </a>


                                    </div>
                                    {/* <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point1.heading') : "1. Verejné služby riadené umelou inteligenciou: "}</h4> */}
                                    {/* <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point1.text') : "Implementovať technológie AI na optimalizáciu poskytovania verejných služieb vrátane zdravotnej starostlivosti, vzdelávania, dopravy čím sa zabezpečí spravodlivý prístup a efektívne prideľovanie zdrojov."}
                                    </p> */}
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point1.heading') : "Hlavný princíp"}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point1.text') : "Strana Spravodlivosť berie tematiku spravodlivosti oveľa vážnejšie než túto tematiku doteraz brali ostatné politické strany. Preto každý programový bod tohto volebného programu jasne definuje v čom zvyšuje spravodlivosť v našej spoločnosti."}
                                    </p>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point1.text2') : "Pre parlamentné voľby 2023 na Slovensku má strana Spravodlivosť "}<span style={{ fontWeight: "bold" }}>{!lang ? t('electoralProgram.point1.text2_1') : "8 hlavných programových tém:"}</span>
                                    </p>

                                    {/* <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point2.heading') : "2. Rozhodovanie založené na údajoch: "}</h4> */}
                                    {/* <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point2.text') : "Využívanie algoritmov AI a analýzy údajov na tvorbu politík založených na dôkazoch, čím sa zabezpečí, že rozhodnutia budú vychádzať z presných informácií a transparentných procesov."}
                                    </p> */}
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point2.heading') : "1. Generačná Spravodlivosť"}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point2.text') : "Zvyšovanie dôchodkov, detských príspevkov a štipendií tak, aby už na začiatku roku 2024 boli na rovnakej úrovni, ako by boli pri normálnom priemernom zvyšovaní v rámci nasledovných 10 rokov. To znamená, že strana Spravodlivosť vyžaduje, aby od 1.1.2024 boli všetky dôchodky zvýšené o 50 percent. Rodinné príspevky pre prvé dve deti a všetky štipendiá musia byť zvýšené k 1.1.2024 o 100 percent."}
                                    </p>
                                    <MDBContainer className='bg-image hover-overlay shadow-1-strong rounded' style={{ maxWidth: "1000px", marginBottom: "1rem", backgroundColor: "#111a22", borderRadius: "15px" }}>
                                        <MDBListGroup>
                                            <MDBListGroupItem tag="a" href={undefined} onClick={toggleCollapse1} action className="hover-bg-change" style={{ backgroundColor: "#111a22", margin: "10px 0 10px 0", borderColor: "#111a22" }}>
                                                <MDBTypography className="heading-hover" tag="h5" style={{ cursor: "pointer", userSelect: "none" }}>{!lang ? t("electoralProgram.point1.explain") : "* Vysvetlenie"}</MDBTypography>
                                                <MDBCollapse show={collapse1} style={{ cursor: "pointer", userSelect: "none" }}>
                                                    {!lang ?
                                                        <p>
                                                            {t("electoralProgram.point1.textExplain1")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point1.textExplain2")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point1.textExplain3")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point1.textExplain4")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point1.textExplain5")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point1.textExplain6")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point1.textExplain7")}
                                                        </p>
                                                        :
                                                        <p>Celá generácia dnešných dôchodkov je trestaná transformáciou systému, ktorá nastala za posledných 34 rokov. Aj keď ekonomickú situáciu mladšia generácia o niečo zlepšovala, dôchodky zostali také nízke, že je to národnou hanbou Slovenska a obrovskou nespravodlivosťou voči dôchodcom, ktorí pracovali celý svoj život.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Strana Spravodlivosť neakceptuje túto generačnú nespravodlivosť a preto vyžaduje zvýšenie dôchodkov skokovo skôr, ako by zvyšovanie nastávalo postupne. Vychádzame z predpokladov, že aj bez politiky strany Spravodlivosť budú musieť byť dôchodky zvýšené ročne v priemere o 6 percent (aj v minulosti boli dôchodky každoročne zvyšované). To znamená, že pri tomto spôsobe zvyšovania dôchodky budú len o 10 rokov oproti aktuálnym stavom  zvýšené o 100 %. Ale dovtedy budú mať naši dôchodcovia nespravodlivo ťažký život, a veľa  z nich sa lepších časov bohužiaľ ani nedožije.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Preto strana Spravodlivosť vyžaduje, aby k 1.1.2024 boli všetky dôchodky zvýšené o 50 percent – následne ďalších 10 rokov by zostali dôchodky rovnaké. To znamená, že celkovo bude v budúcich 10 rokoch vyplatená rovnaká suma pre dôchodky ako bez mimoriadneho zvyšovania k 1.1.2024.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Skoršie zvyšovanie nás nič nestojí, pretože to čo bude vyplatené v rámci najbližších 5 rokoch navyše, bude v ďalších 5 rokoch vykompenzované (ale stále to bude o 50 percent viac ako dnes). Štát musí toto zvýšenie len prefinancovať. Reálne to štát nič nestojí, pretože úroky, ktoré štát musí zaplatiť sú nižšie ako je inflácia. Tým pádom štát v reálnych hodnotách nemusí vrátiť viac ako si požičal.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Tento koncept skoršieho zvýšenia dôchodkov je inteligentným spôsobom ako v prospech obyvateľov využiť existenciu mimoriadne nízkych reálnych úrokov - a  by bola veľká škoda nevyužiť to!
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Rodinné príspevky a štipendiá môžu byť zvýšené na základe rovnakej logiky o 100 percent, pretože 1.1.2024 nebudú zvýšené všetky príspevky, ale len príspevky pre prvé dve deti. Aj to je dôležitý aspekt Generačnej Spravodlivosti. Len rodičia cez ich deti zabezpečia, aby bol sociálny systém dlhodobo udržateľný. Za to majú právo, aby sa štát podieľal na nákladoch na výchovu detí.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Vyššie štipendiá zabezpečia, že viac študentov môže úspešne dosiahnuť kvalitné vzdelanie a s lepšími výsledkami ukončiť ich vzdelanie. Pre dobrý vývoj ekonomiky a spoločnosti je potrebný dostatoční počet detí a rovnako aj ich kvalitné vzdelávanie.
                                                        </p>}
                                                </MDBCollapse>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBContainer>
                                    {/* <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point3.heading') : "3. Prediktívna zdravotná starostlivosť: "}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point3.text') : "Využívajte AI a prediktívne modelovanie na identifikáciu zdravotných rizík a trendov, čo umožní prijať proaktívne opatrenia na prevenciu prepuknutia chorôb, zlepšenie verejného zdravia a efektívne prideľovanie zdrojov zdravotnej starostlivosti."}
                                    </p> */}
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point3.heading') : "2. Súdna Spravodlivosť"}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point3.text') : "Strana Spravodlivosť vo svojom programe požaduje, aby pri všetkých súdnych rozhodnutiach  bola použitá umelá inteligencia. Tento krok by výrazne zrýchlil a objektivizoval súdne procesy."}
                                    </p>
                                    <MDBContainer style={{ maxWidth: "1000px", marginBottom: "1rem", backgroundColor: "#111a22", borderRadius: "15px" }}>
                                        <MDBListGroup>
                                            <MDBListGroupItem tag="a" href={undefined} onClick={toggleCollapse2} action style={{ backgroundColor: "#111a22", margin: "10px 0 10px 0", borderColor: "#111a22" }}>
                                                <MDBTypography className="heading-hover" tag="h5" style={{ cursor: "pointer", userSelect: "none" }}>{!lang ? t("electoralProgram.point1.explain") : "* Vysvetlenie"}</MDBTypography>
                                                <MDBCollapse show={collapse2} style={{ cursor: "pointer", userSelect: "none" }}>
                                                    {!lang ?
                                                        <p>
                                                            {t("electoralProgram.point2.textExplain1")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point2.textExplain2")}
                                                        </p>
                                                        :
                                                        <p>
                                                            Súdne procesy trvajú na Slovensku príliš dlho. To spôsobuje veľa problémov a nespravodlivostí. Slovenský štát existuje už 30 rokov a nikto nebol schopný priniesť riešenie na tento problém. Preto Strana Spravodlivosť vyžaduje systematické používanie umelej inteligencie v rámci súdnictva. Tým sa má zabezpečiť, že vždy pred rozhodnutím súdu bude k dispozícií komplexná právna analýza. Pomocou umelej inteligencie je možné vo veľmi krátkom čase prejsť a analyzovať všetky relevantné súdne rozhodnutia, ktoré už existujú pre podobné prípady. Takáto analýza môže zohľadňovať aj akademickú literatúru. Individuálny sudca nemá šancu preštudovať všetko, čo by bolo potrebné pre konzistentné rozhodnutie. Pomocou umelej inteligencie môžu sudcovia rozhodnúť rýchlejšie, ale aj spravodlivejšie s nižšou úrovňou subjektivity. Pre občana sa stanú súdne rozhodnutia lepšie predvídateľnými.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Sudcovia budú naďalej robiť konečné rozhodnutia a Slovensko sa môže stať týmto prístupom najmodernejšou krajinou na svete.
                                                        </p>}
                                                </MDBCollapse>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBContainer>
                                </div>
                                <div id="text-id-2">
                                    {/* <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point4.heading') : "4. Inteligentné plánovanie infraštruktúry: "}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point4.text') : "Využívanie systémov poháňaných umelou inteligenciou na plánovanie miest, riadenie dopravy a rozvoj infraštruktúry s cieľom zvýšiť efektívnosť, znížiť dopravné zápchy a podporiť udržateľné a zdravé životné prostredie."}
                                    </p> */}
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point4.heading') : "3. Byrokratická Spravodlivosť"}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point4.text') : "Strana Spravodlivosť navrhuje zrušenie až 50% všetkých zákonov. Pomocou umelej inteligencie sa zistia najškodlivejšie zákony, ktoré nie sú v záujme občanov a existujú len z dôvodu, že boli presadené niekým, kto má z ich existencie výhody."}
                                    </p>
                                    <MDBContainer style={{ maxWidth: "1000px", marginBottom: "1rem", backgroundColor: "#111a22", borderRadius: "15px" }}>
                                        <MDBListGroup>
                                            <MDBListGroupItem tag="a" href={undefined} onClick={toggleCollapse3} action style={{ backgroundColor: "#111a22", margin: "10px 0 10px 0", borderColor: "#111a22" }}>
                                                <MDBTypography className="heading-hover" tag="h5" style={{ cursor: "pointer", userSelect: "none" }}>{!lang ? t("electoralProgram.point1.explain") : "* Vysvetlenie"}</MDBTypography>
                                                <MDBCollapse show={collapse3} style={{ cursor: "pointer", userSelect: "none" }}>
                                                    {!lang ?
                                                        <p>
                                                            {t("electoralProgram.point3.textExplain1")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            <span style={{ display: "block", marginLeft: "15px" }}>
                                                                <span>{"i)"} </span>{t("electoralProgram.point3.textExplain1point1")}
                                                                <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            </span>
                                                            <span style={{ display: "block", marginLeft: "15px" }}>
                                                                <span>{"ii)"} </span>{t("electoralProgram.point3.textExplain1point2")}
                                                                <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            </span>
                                                            {t("electoralProgram.point3.textExplain2")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point3.textExplain3")}
                                                        </p>
                                                        :
                                                        <p>
                                                            Odkedy sa Slovensko stalo samostatným  štátom, byrokracia veľmi silno narástla. Môže to mať nasledovné zlé dôsledky:
                                                            <span style={{ display: "block", marginBottom: "15px" }} />
                                                            <span style={{ display: "block", marginLeft: "15px" }}>
                                                                i) alebo sú úradníci prepracovaní a ľudia dostanú zlý servis od nefunkčného štátu,
                                                                <br />
                                                                <span style={{ display: "block", marginBottom: "15px" }} />
                                                            </span>
                                                            <span style={{ display: "block", marginLeft: "15px" }}>
                                                                ii) alebo štát zamestnáva a platí dostatočný počet úradníkov. Potom, ale musia byť dane a odvody také vysoké, že je tým silne znížená životná úroveň kvôli prehnanej byrokracii.
                                                                <br />
                                                                <span style={{ display: "block", marginBottom: "15px" }} />
                                                            </span>
                                                            Zatiaľ tento problém nebol v minulosti vôbec riešený – napriek tomu, že veľa ľudí sa na byrokraciu sťažuje. Ale sťažovať sa nestačí. Riešenie môže vzniknúť len keď je konkrétne a drastické opatrenie realizované. Presne to teraz spraví Strana Spravodlivosť s tým, že vyžaduje aby sa zredukoval počet zákonov o 50 %. Len keď bude zredukovaný počet a množstvo zákonov a nariadení, môže aj menej úradníkov zabezpečiť dobre fungujúci štát. Toto opatrenie zvýši životnú úroveň, lebo na pokrytie nákladov sú potrebné nižšie dane a odvody. To platí aj v prípade, že úradníci budú dostávať adekvátne platy, ktoré sú konkurencieschopné na trhu práce.
                                                            <br />
                                                            <span style={{ display: "block", marginBottom: "15px" }} />
                                                            Pomocou systému umelej inteligencie má byť v rámci budúcej vládnucej periódy identifikovaných 50% najškodlivejších zákonov. Hlavné kritéria pre toto preskúmanie má byť rozsah, ktorým zákon zaťažuje byrokratický aparát v porovnaní s dôležitosťou konkrétneho zákona pre občanov. Je jasné, že  zákony, ktoré slúžia len istým vplyvným skupinám, neplnia tieto kritériá a preto môžu byť zrušené.
                                                        </p>}
                                                </MDBCollapse>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBContainer>
                                    {/* <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point5.heading') : "5. Monitorovanie a ochrana životného prostredia: "}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point5.text') : "Zavádzanie systémov poháňaných umelou inteligenciou na monitorovanie a analýzu údajov o životnom prostredí, ktoré uľahčujú účinné opatrenia na boj proti znečisteniu, ochranu ekosystémov a podporu udržateľného rozvoja."}
                                    </p> */}
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point5.heading') : "4. Imigračná Spravodlivosť"}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point5.text') : "Strana Spravodlivosť nahradí doteraz platný ale nefunkčný a nespravodlivý azylový systém novým systémom získavania trvalého bydliska a pracovných povolení.  Po zrušení existujúceho zákona o azyle už nebude možné požiadať o azyl na Slovensku. Týmto krokom sa Slovensko oslobodí z európskej azylovej krízy."}
                                    </p>
                                    <MDBContainer style={{ maxWidth: "1000px", marginBottom: "1rem", backgroundColor: "#111a22", borderRadius: "15px" }}>
                                        <MDBListGroup>
                                            <MDBListGroupItem tag="a" href={undefined} onClick={toggleCollapse4} action style={{ backgroundColor: "#111a22", margin: "10px 0 10px 0", borderColor: "#111a22" }}>
                                                <MDBTypography className="heading-hover" tag="h5" style={{ cursor: "pointer", userSelect: "none" }}>{!lang ? t("electoralProgram.point1.explain") : "* Vysvetlenie"}</MDBTypography>
                                                <MDBCollapse show={collapse4} style={{ cursor: "pointer", userSelect: "none" }}>
                                                    {!lang ?
                                                        <p>
                                                            {t("electoralProgram.point4.textExplain1")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain2")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain3")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain4")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain5")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain6")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain7")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain7-1")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain8")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain9")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point4.textExplain10")}
                                                        </p>
                                                        :
                                                        <p>
                                                            Existujúci zákon o azyle vedie k nespravodlivému zaobchádzaniu s ľuďmi. Napriek tomu, že na svete sú najviac diskriminované ženy – azyl  dostávajú skoro výhradne iba mladí muži. Existujúci zákon teda vôbec nezabezpečuje pomoc pre tých, ktorí by to najviac potrebovali!
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Existujúci zákon nezabezpečuje humánny cieľ, ktorému by mal slúžiť. Ale súčasne vedie k veľkým sociálnym a ešte aj nebezpečnejším problémom. V Nemecku, Francúzku, Belgicku, Dánsku a Švédsku vznikli celé štvrte, kde už nefunguje právny štát, kde ženy nemôžu uplatňovať svoje ľudské práva, kriminalita je omnoho vyššia a školy sú horšie ako v ostatných častiach krajiny. Takéto pomery môžu onedlho vzniknúť aj na Slovensku.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            To Slováci určite nechcú a preto strana Spravodlivosť vyžaduje, aby bol zákon o azyle zrušený. Komisia Európskej únie bude proti zrušeniu slovenského zákona o azyle protestovať – to ale nevadí. Komisia môže Slovensko tak či tak iba zažalovať pred Európskym súdom v Luxembursku. Dovtedy, kým ten v priebehu pár rokov rozhodne, aj iné štáty zmenia svoj azylový systém.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Už teraz existuje vo všetkých štátoch EU veľká nespokojnosť s nefunkčným a nespravodlivým azylovým systémom. Slovensko tým pádom bude prvé, ale pri zrušení zákona o azyle nezostane samé. Pritom základný problém nemôže byť riešený cez nejakú reformu zákona o azyle. V západných štátoch skúšajú tento problém riešiť už desaťročia reformami zákona o azyle. Ani jednému európskemu štátu sa to nepodarilo, preto je úplne zrušenie zákona o azyle a jeho náhrada celkom novým systémom jediným realistickým riešením.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Strana Spravodlivosť navrhne nový zákon o povolení na pobyt a prácu. O takéto povolenie sa môžu záujemcovia uchádzať na slovenských veľvyslanectvách všade na svete, ale nie na slovenských hraniciach ako je to v aktuálne platnom azylovom systéme.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Tento nový zákon bude definovať kritériá pre získanie povolenia. Za plnenie každého kritéria získa uchádzač body a tí uchádzači, ktorí prekročia vyžadovanú úroveň dostanú trvalé povolenie na pobyt a na prácu. Keď niekto bude odsúdený za trestný čin, stratí toto povolenie.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Vláda môže pre každý rok stanoviť počet bodov, ktoré sú potrebné pre získanie povolenia pre pobyt a prácu. S tým sa dá ľahko a v transparentnej forme regulovať koľko ľudí a s akým profilom môže dostať povolenie na pobyt a prácu na Slovensku.
                                                            Kritériá pre získanie bodov majú zahŕňať vek, pohlavie, vzdelanie, skúsenosti, či je diskriminovaný v domácej krajne a jeho/jej postoj k demokracii a ľudským právam.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Pri doterajšom azylovom systéme je úplne absurdný malo známy fakt, že v rámci aktuálneho byrokratického procesu žiadosti o azyl vôbec nie je zisťované, či aplikant má demokratický postoj alebo či je proti demokracii. Aktuálny systém dokonca nepoužíva ako kritérium ani to, či azylant sám rešpektuje ľudské práva svojich dcér alebo sestier. Teda aj ľudia, ktorí nútia svoje dcéry alebo sestry do nedobrovoľného manželstva alebo do nedobrovoľného nosenia šatky na hlave, môžu dostať azyl. V rámci nového systému to bude úplne iné, pretože rešpektovanie ľudských práv a princípov demokracie budú explicitne stanovené ako vylučovacie kritériá.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Tento nový systém bude teda, na rozdiel od existujúceho systému azylu, humánny. V  rámci nového systému sa môžu uchádzať všetky záujemcovia a nielen ľudia ktorí majú dosť peňazí na zaplatenie kriminálnych organizácií, ktoré realizujú pašovanie ľudí až na slovenskú hranicu.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Prvýkrát budú mať ženy rovnakú šancu ako muži. Vo viacerých štátoch sú ženy stále považované za menejcenné ľudské bytosti, kvôli tomu rodiny zabezpečujú peniaze potrebné pre pašerácke organizácie len pre svojich synov. To je hlavný dôvod prečo ako azylanti k nám prichádzajú skoro výhradne muži.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            V rámci nového zákona pre povolenie pre pobyt a prácu peniaze pre pašerákov už nebudú potrebné –  ženy konečne nebudú diskriminované.
                                                        </p>}
                                                </MDBCollapse>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBContainer>
                                    {/* <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point6.heading') : "6. Zapojenie občanov a spätná väzba: "}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point6.text') : "Vyvíjať platformy poháňané umelou inteligenciou na zapojenie občanov, ktoré umožnia spätnú väzbu v reálnom čase, účasť na rozhodovacích procesoch a podporia inkluzívnu a citlivú vládu."}
                                    </p> */}
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point6.heading') : "5. Európska spravodlivosť"}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point6.text') : "Strana Spravodlivosť nesúhlasí so stále väčším preberaním právomocí Európskej únie. Je tým systematicky oslabovaná samostatnosť a národná demokracia Slovenska a aj ostatných členských štátov. Preto strana Spravodlivosť vyžaduje zrušenie polovice európskych nariadení a regulácií v priebehu nasledujúcich piatich rokov. Týmito zrušeniami bude európska únia donútená koncentrovať sa na najdôležitejšie témy, pri ktorých je regulácia na úrovni európskej únie zmysluplná."}
                                    </p>
                                    <MDBContainer style={{ maxWidth: "1000px", marginBottom: "1rem", backgroundColor: "#111a22", borderRadius: "15px" }}>
                                        <MDBListGroup>
                                            <MDBListGroupItem tag="a" href={undefined} onClick={toggleCollapse5} action style={{ backgroundColor: "#111a22", margin: "10px 0 10px 0", borderColor: "#111a22" }}>
                                                <MDBTypography className="heading-hover" tag="h5" style={{ cursor: "pointer", userSelect: "none" }}>{!lang ? t("electoralProgram.point1.explain") : "* Vysvetlenie"}</MDBTypography>
                                                <MDBCollapse show={collapse5} style={{ cursor: "pointer", userSelect: "none" }}>
                                                    {!lang ?
                                                        <p>
                                                            {t("electoralProgram.point5.textExplain1")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point5.textExplain2")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point5.textExplain3")}
                                                        </p>
                                                        :
                                                        <p>
                                                            Európska únia má snahu preberať na seba – na úkor členských štátov - stále viac kompetencií. Proti tejto tendencii doteraz neexistovala žiadna ochrana. Strana Spravodlivosť je proti tejto snahe EÚ a vyžaduje jasné ohraničenie pôsobenia Európskej únie. Podľa Strany Spravodlivosť má Európska únia robiť len to, v  čom je jasné a nesporné, že výhody Európskej regulácie sú výrazne väčšie než keby to každý členský štát spravil sám.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Za účelom realizovania potrebnej zmeny, Strana Spravodlivosť vyžaduje aby Európska únia v rámci nasledujúcich piatich rokov zredukovala počet zákonov a nariadení o 50%. Len týmto konzekventným postojom je možné zabrániť tomu aby vznikol byrokratický Super štát v Bruseli.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Obmedzenie Európskej únie je potrebné aj na to, aby väčšina obyvateľstva naďalej považovala štát za demokratický. Keď je príliš veľa vecí rozhodnutých v Bruselskej byrokracii, ktorá je pre občanov netransparentná a nepochopiteľná, potom prirodzene vzniká názor, že už nežijeme v demokracii. To zasa zvyšuje riziko, že frustrovaní občania budú voliť nedemokratické strany.
                                                        </p>}
                                                </MDBCollapse>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBContainer>
                                </div>
                                <div id="text-id-3">
                                    {/* <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point7.heading') : "7. Etické usmernenia pre umelú inteligenciu: "}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point7.text') : "Zaviesť jasné usmernenia a predpisy pre etický vývoj a zavádzanie technológií umelej inteligencie, ktoré zabezpečia súkromie, bezpečnosť a ochranu pred algoritmickou zaujatosťou alebo diskrimináciou."}
                                    </p> */}
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point7.heading') : "6. Technologická Spravodlivosť"}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point7.text') : "Strana Spravodlivosť vyžaduje aby Slovensko investovalo do vytvorenia národného systému umelej inteligencie. To výrazne posilní technologickú atraktívnosť a konkurencieschopnosť krajiny a následne s istotou prinesie všeobecné zvýšenie úrovne miezd."}
                                    </p>
                                    <MDBContainer style={{ maxWidth: "1000px", marginBottom: "1rem", backgroundColor: "#111a22", borderRadius: "15px" }}>
                                        <MDBListGroup>
                                            <MDBListGroupItem tag="a" href={undefined} onClick={toggleCollapse6} action style={{ backgroundColor: "#111a22", margin: "10px 0 10px 0", borderColor: "#111a22" }}>
                                                <MDBTypography className="heading-hover" tag="h5" style={{ cursor: "pointer", userSelect: "none" }}>{!lang ? t("electoralProgram.point1.explain") : "* Vysvetlenie"}</MDBTypography>
                                                <MDBCollapse show={collapse6} style={{ cursor: "pointer", userSelect: "none" }}>
                                                    {!lang ?
                                                        <p>
                                                            {t("electoralProgram.point6.textExplain1")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point6.textExplain2")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point6.textExplain3")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point6.textExplain4")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point6.textExplain5")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point6.textExplain6")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point6.textExplain7")}
                                                        </p>
                                                        :
                                                        <p>
                                                            Slovensko nevyužilo možnosť patriť medzi popredné štáty pri digitalizácii. Iné bývalé komunistické štáty to urobili oveľa lepšie. Napríklad Estónsko a Slovinsko patria dnes medzi najvyspelejšie štáty na svete, čo sa týka digitalizácie. Silne sa zlepšil image týchto štátov a s tým je spojený aj rýchly rast ich životnej úrovne.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Teraz je tu nová príležitosť – umelá inteligencia. Strana Spravodlivosť chce, aby Slovensko bolo prvým štátom na svete, ktorý využíva túto novú technológiu tak, aby dosiahlo konkurenčnú výhodu oproti iným štátom.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Za týmto účelom Strana Spravodlivosť vyžaduje budovanie národného systému umelej inteligencie. Systém má byť trénovaný na základe všetkých kníh a publikácii v slovenskom jazyku a dostupných databáz.
                                                            Tým sa zabezpečí, že odpovede tohto systému zohľadnia všetky okolnosti, ktoré sú na Slovensku relevantné. Pri množstve odpovedí  bude takýto „slovenský“ systém reagovať nápomocnejšie a pre Slovákov relevantnejšie než systémy, ktoré sú trénované prevažne americkými predpokladmi. Tým budú mať ľudia, ktorí používajú slovenčinu, aspoň pre časť tém, podobné možnosti ako ľudia, ktorí majú anglický jazyk ako hlavný jazyk. To je dôležitý aspekt technologickej Spravodlivosti.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Takýto systém pritiahne aj veľa investícií a nových projektov tak od existujúcich, ako aj od nových firiem. Tieto investície prídu bez štátnych  subvencií a bez investičných stimulov. V konečnom dôsledku to štát nebude stáť nič, pretože pôvodné investície budú kompenzované tými úsporami, že štát nebude musieť platiť subvencie a investičné stimuly.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Slovensko ma najsilnejší odliv vysoko kvalifikovaných ľudí. Tomuto škodlivému odlivu sa dá zabrániť realizáciou národného systému umelej inteligencie. S určitosťou sa veľa ľudí vráti zo zahraničia využiť nové možnosti, ktoré takto na Slovensku vzniknú.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Teraz Slovensku hrozí, že platy a iné formy príjmov už nebudú rýchlejšie rásť, ako rastú v západnej Európe. To by znamenalo skutočnú katastrofu, lebo Slováci by stratili možnosť dobiehať západ a zostali by dlhodobo občanmi druhej kategórie v Európe s výrazne nižšími príjmami.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Tomu za dá zabrániť len tým, že Slovensko bude výrazne zlepšovať niečo skutočne dôležité. Národný systém umelej inteligencie spolu s ostatnými programovými bodmi Strany Spravodlivosť sú opatrenia, ktoré sú dostatočne silné a zabezpečia že slovenská ekonomika môže naďalej rásť rýchlejšie ako európsky priemer a spolu s tým budú postupne aj slovenské platy dobiehať európsku úroveň.
                                                        </p>}
                                                </MDBCollapse>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBContainer>
                                    {/* <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point8.heading') : "8. Vzdelávanie a zvyšovanie kvalifikácie: "}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point8.text') : "Podporujte gramotnosť v oblasti AI a poskytnite vzdelávacie programy, aby občania a štátni zamestnanci získali potrebné zručnosti na pochopenie a efektívne zapojenie systémov založených na AI."}
                                    </p> */}
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point8.heading') : "7. Volebná Spravodlivosť"}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point8.text') : "Strana Spravodlivosť trvá na tom, aby rodičia dostali volebné práva za svoje neplnoleté deti. Tým sa zabezpečí, že rozhodnutia budú zohľadňovať dlhodobé potreby spoločnosti a každý občan bude mať rovnaké volebné právo."}
                                    </p>
                                    <MDBContainer className='bg-image hover-overlay shadow-1-strong rounded' style={{ maxWidth: "1000px", marginBottom: "1rem", backgroundColor: "#111a22", borderRadius: "15px" }}>
                                        <MDBListGroup>
                                            <MDBListGroupItem tag="a" href={undefined} onClick={toggleCollapse7} action className="hover-bg-change" style={{ backgroundColor: "#111a22", margin: "10px 0 10px 0", borderColor: "#111a22" }}>
                                                <MDBTypography className="heading-hover" tag="h5" style={{ cursor: "pointer", userSelect: "none" }}>{!lang ? t("electoralProgram.point1.explain") : "* Vysvetlenie"}</MDBTypography>
                                                <MDBCollapse show={collapse7} style={{ cursor: "pointer", userSelect: "none" }}>
                                                    {!lang ?
                                                        <p>
                                                            {t("electoralProgram.point8.textExplain1")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point8.textExplain2")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point8.textExplain3")}
                                                        </p>
                                                        :
                                                        <p>
                                                            Každý občan má mať rovnaké volebné právo. Strana Spravodlivosť vyžaduje aby to platilo skutočne pre všetkých – aj pre deti. Samozrejme, volebné právo pre deti majú uplatniť rodičia. Keď rodičia dostanú toto volebné právo, automaticky celá politika bude viac orientovaná na to, čo je dlhodobo realistické.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            K tomu patrí aj to, že životné podmienky pre ženy budú výrazne zlepšované tak, že priemerná pôrodnosť porastie na 2,1 %. Obyvateľstvo je dlhodobo stabilné iba ak majú ženy v priemere minimálne dve deti. Keď je pôrodnosť nižšia (na Slovensku je to teraz okolo 1,4 %), spoločnosť starne a sociálny systém sa nevyhnutne zrúti. Je to teda vysoko nezodpovedné voči budúcemu osudu spoločnosti nevytvoriť dostatočne atraktívne životné podmienky pre rodičov.
                                                            Strana Spravodlivosť chce presadiť potrebné zlepšovania životných podmienok pre rodičov a hlave pre mladé ženy, ktoré chcú mať deti, ale doteraz si to  kvôli nedobrým podmienkam nemohli dovoliť.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Potrebné zlepšenia sa dajú najlepšie presadiť tým, že rodičia dostanú volebné práva pre ich neplnoleté deti.
                                                        </p>}
                                                </MDBCollapse>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBContainer>
                                    {/* <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point9.heading') : "9. Digitálna inklúzia: "}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }} >
                                        {!lang ? t('electoralProgram.point9.text') : "Preklenúť digitálnu priepasť zabezpečením prístupu k technológiám AI a digitálnej infraštruktúre pre všetkých občanov bez ohľadu na ich sociálno-ekonomický status a poskytovaním podpory marginalizovaným komunitám."}
                                    </p> */}
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point9.heading') : "8. Poľnohospodárska Spravodlivosť"}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }} >
                                        {!lang ? t('electoralProgram.point9.text') : "Strana Spravodlivosť vyžaduje zmenu dotácie systému tak, aby každá rodina respektíve spoločnosť, ktorá podniká v poľnohospodárstve, dostala príspevok v rovnakej výške -  nezávisle od veľkosti pôdy, ktorú obrába. Tento krok by mohol dvojnásobne zvýšiť sumu peňazí, ktorú v priemere dostanú slovenskí poľnohospodári pri rovnakom celkovom objeme peňazí v Európskom systéme. Menej by dostali hlavne veľké priemyselné poľnohospodárske podniky, napríklad v Nemecku, kde existujú spoločnosti, ktoré chovajú aj viac tisíc ošípaných."}
                                    </p>
                                    <MDBContainer className='bg-image hover-overlay shadow-1-strong rounded' style={{ maxWidth: "1000px", marginBottom: "1rem", backgroundColor: "#111a22", borderRadius: "15px" }}>
                                        <MDBListGroup>
                                            <MDBListGroupItem tag="a" href={undefined} onClick={toggleCollapse8} action className="hover-bg-change" style={{ backgroundColor: "#111a22", margin: "10px 0 10px 0", borderColor: "#111a22" }}>
                                                <MDBTypography className="heading-hover" tag="h5" style={{ cursor: "pointer", userSelect: "none" }}>{!lang ? t("electoralProgram.point1.explain") : "* Vysvetlenie"}</MDBTypography>
                                                <MDBCollapse show={collapse8} style={{ cursor: "pointer", userSelect: "none" }}>
                                                    {!lang ?
                                                        <p>
                                                            {t("electoralProgram.point9.textExplain1")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point9.textExplain2")}
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            {t("electoralProgram.point9.textExplain3")}
                                                        </p>
                                                        :
                                                        <p>
                                                            Dnešný systém dotácií Európskej únie nie je dobrý pre slovenský vidiek. Najvyššie dotácie dostanú priemyselné poľnohospodárske firmy, ktoré majú obrovské statky.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            Na Slovensku už skoro neexistujú také veľké priemyselné hospodárske firmy, pretože družstvá, ktoré existovali v minulosti sa rozpadli. V súčasnosti už existujú poľnohospodárske kolosy len v iných štátoch, ktoré ako jediné silne profitujú zo systému dotácií EU. Strana Spravodlivosť vyžaduje to, čo je pre Slovensko a v tomto prípade pre slovenský vidiek najlepšie. Systém dotácií, ktorý nezávisí od veľkosti pôdy, ale paušálne dotácie pre každú farmársku rodinu, ktorá plní isté minimálne kritéria. Len touto fundamentálnou zmenou je možné zabezpečiť, že príde k oživeniu vidieku a všetkých dolín na celom Slovensku.
                                                            <br /><span style={{ display: "block", marginBottom: "15px" }} />
                                                            A len keď slovenský vidiek prežije je zabezpečené zásobovanie miest v čase krízy. Ako ukázalo posledných pár rokov, veľmi rýchlo môže vypuknúť ďalšia kríza, kde nebude možné spoľahnúť sa na medzinárodné zásobovanie – ani so základnými potravinami.
                                                        </p>}
                                                </MDBCollapse>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBContainer>
                                </div>
                                {/* <div id="text-id-4">
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point10.heading') : "10. Partnerstvá založené na spolupráci: "}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point10.text') : "Podporovať spoluprácu medzi vládou, akademickou obcou a priemyslom s cieľom podporiť inovácie, výskum a vývoj v oblasti aplikácií AI pre správu vecí verejných a zároveň zabezpečiť zodpovedné a inkluzívne postupy."}
                                    </p>
                                    <h4 style={{ color: "rgb(182, 182, 182)" }}>{!lang ? t('electoralProgram.point11.heading') : "11. Optimalizácia financovania športu pomocou AI: "}</h4>
                                    <p style={{ color: "rgb(109, 109, 109)" }}>
                                        {!lang ? t('electoralProgram.point11.text') : "Jedným z kľúčových výziev športového sektora na Slovensku je efektívne a transparentné rozdelenie financií medzi rôznymi športovými disciplínami, klubmi a jednotlivými športovcami. Vytvoríme model, ktorý bude schopný analyzovať výkony športovcov, potreby jednotlivých disciplín, aktuálne trendy a potenciál rastu.Tento model bude tiež schopný hodnotiť efektívnosť doterajších investícií do športu, identifikovať oblasti, ktoré potrebujú viac financií, a odporučiť, ako najlepšie alokovať prostriedky."}
                                    </p>
                                </div>
                                <div id="text-id-5">
                                    <p>{!lang ? t('electoralProgram.paragraph') : "* Je dôležité poznamenať, že zavádzanie AI do správy vecí verejných by mali sprevádzať robustné právne rámce, silné mechanizmy riadenia a priebežné monitorovanie a hodnotenie s cieľom riešiť potenciálne riziká a zabezpečiť, aby sa technológia používala eticky, transparentne a v najlepšom záujme všetkých občanov."}</p>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="col-md-4" >
                        <div className="div" style={{ position: "sticky", top: "0" }}>
                            <div className="logo-container">
                                <NavLink
                                    to="/"
                                    className={(navData) =>
                                        navData.isActive ? "active-style" : "none"
                                    }
                                >
                                    <img
                                        className="p-4"
                                        alt="header-logo"
                                        id="program-logo-transparent-id"
                                        src={TransparentLogo}
                                    />
                                </NavLink>
                            </div>
                        </div>
                    </div> */}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default VolebnyProgram;
