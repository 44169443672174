import React, { useEffect, useState } from "react";
import Table4 from "../assets/img/table4.png";
import { Link } from "react-router-dom";
import "../assets/css/mandatory-doc.css";
import { Helmet } from "react-helmet-async";
import { hideLoader, showLoader, isLangSk } from "../assets/js/helpers";
import { useTranslation } from "react-i18next";

const DocumentOdComponent = () => {
  const [t, i18n] = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    showLoader();
    i18n.changeLanguage(localStorage.getItem("lang"));
    let lang = isLangSk();
    setLang(lang);
    setTimeout(hideLoader, 500);
  }, []);

  return (
    <div style={{ marginTop: "3%", marginTop: "120px", marginBottom: "120px" }}>
      <div className="container" style={{ height: "100%" }}>
        <div className="doc-od-container">
          <Helmet>
            <title>
              Povinne zverejňované informácie od 2.5.2023 | Spravodlivosť |
              Strana Spravodlivosť | Politická strana Spravodlivosť | Voľby 2023
            </title>
            <meta
              name="description"
              content="Spravodlivosť | Strana Spravodlivosť | Politická strana Spravodlivosť | Voľby 2023"
            ></meta>
            <meta
              name="keywords"
              content="Spravodlivosť | Strana Spravodlivosť | Politická strana Spravodlivosť | Voľby 2023"
            />
            <link rel="canonical" href={`/povinne-info/document-od`}></link>
          </Helmet>
          {/* <div className="table4-img-container">
            <img className="table4-img" src={Table4} alt="Table2023" />
          </div> */}
          <h2>
            {!lang
              ? t("mandatoryFrom.text2")
              : "Údaje o prijatých pôžičkách v roku 2023"}
              
          </h2>
          <div className="table-responsive">
            <table
              className="table table-bordered table-hover"
              style={{
                color: "#b0b0b0",
                border: "1px solid white",
                marginTop: "25px",
              }}
            >
              <thead style={{ background: "transparent" }}>
                <tr
                  className="table-head-class"
                  style={{ border: "1px solid white" }}
                >
                  <th
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    
                    {!lang ? t("mandatoryFrom.firstTableH1") : "Výška pôžičky dohodnutý úrok"}
                  </th>
                  <th
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    {!lang ? t("mandatoryFrom.firstTableH2") : "Veriteľ"}
                    
                  </th>
                  <th
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    {!lang ? t("mandatoryFrom.firsTableH3") : "Trvalý pobyt sídlo"} 
                    
                  </th>
                  <th
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    {!lang ? t("mandatoryFrom.firstTableH4") : "Doba splatnosti"}
                    
                  </th>
                  <th
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    {!lang ? t("mandatoryFrom.firstTableH5") : "Dátum uzatvorenia zmluvy"}
                    
                  </th>
                </tr>
              </thead>
              <tbody
                className="table-document-od"
                style={{ border: "1px solid white" }}
              >
                <tr style={{ border: "1px solid white" }}>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    18000 eur
                    <br />

                  {!lang ? t("mandatoryFrom.firstTableText1") : "bezúročná"}
                    
                  </td>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    Ing. JUDr. Soňa
                    <br />
                    Gosťová
                  </td>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    Anízova 20409/25
                    <br />
                    821 07 Bratislava-Vrakuňa
                  </td>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    <br />
                    31.12.2024
                  </td>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    <br />
                    23.06.2023
                  </td>
                </tr>
                <tr style={{ border: "1px solid white" }}>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    15000 eur
                    <br />
                    {!lang ? t("mandatoryFrom.firstTableText1") : "bezúročná"}
                  </td>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    JUDr. Gustáv
                    <br />
                    Ret
                  </td>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    Vansovej 2<br />
                    811 03 Bratislava
                  </td>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    <br />
                    31.12.2024
                  </td>
                  <td
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid",
                      color: "#b0b0b0",
                    }}
                  >
                    <br />
                    03.08.2023
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
          {!lang ? t("mandatoryFrom.firstParagraph") : "Zoznam osôb, ktoré prispeli na činnosť politickej strany spravodlivosťv kalendárnom rokum 2023 peňažnými alebo nepeňažnými darmi, inými bezodplatnými plneniami alebo členskými príspevkami v hodnote vyššej ako je dvojnásobok minimálnej mzdy zamestnanca odmeňovaného mesačnou mzdou platnej v čase poskytnutia tohto plnenia podľa § 22 ods. 5 zákona č.85/2005 z.z. o politických stranách a politických hnutiach v znení neskorších právnych predpisov:"}
            {/* {!lang ? t("mandatoryFrom.") : ""} */}
          </p>
          <div className="table-responsive">
            <table className="table table-bordered table-hover" style={{ color: "#b0b0b0", border: "1px solid white",marginTop: "25px" }}>
              <thead style={{ background: "transparent" }}>
                <tr className="table-head-class" style={{ border: "1px solid white" }}>
                  <th style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0" }}>{!lang ? t("mandatoryFrom.secondTableH1") : "Meno/Priezvisko Obchodné meno"}</th>
                  <th style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>{!lang ? t("mandatoryFrom.secondTableH2") : "Adresa trvalého pobytu Sídlo"}</th>
                  <th style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>{!lang ? t("mandatoryFrom.secondTableH3") : "IČO"}</th>
                  <th style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>{!lang ? t("mandatoryFrom.secondTableH4") : "Typ príjmu"}</th>
                  <th style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>{!lang ? t("mandatoryFrom.secondTableH5") : "Výška/hodnota(eur)"}</th>
                  <th style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>{!lang ? t("mandatoryFrom.secondTableH6") : "Predmet"}</th>
                </tr>
              </thead>
              <tbody className="table-document-od" style={{ border: "1px solid white" }}>
                <tr style={{ border: "1px solid white" }}>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>Ing. JUDr. Soňa Gosťová</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>Anízová 20409/25 <br></br>821 07 Bratislava-Vrakuňa</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}></td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}><br />{!lang ? t("mandatoryFrom.secondTableFirstText") : "peňažný dar"}</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}><br />2000</td>
                </tr>
                <tr style={{ border: "1px solid white" }}>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>PaedDr. Zuzana Šóšiková</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>Točnica 116 <br></br>985 22 Cinobaňa</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}></td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}><br />{!lang ? t("mandatoryFrom.secondTableFirstText") : "peňažný dar"}</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}><br />2000</td>
                </tr>
                <tr style={{ border: "1px solid white" }}>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>RNDr. Ferdinand Búci</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>Hrobákova 1637/9 <br></br>851 02 Bratislava-Petržalka</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}></td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}><br />{!lang ? t("mandatoryFrom.secondTableFirstText") : "peňažný dar"}</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}><br />2000</td>
                </tr>
                <tr style={{ border: "1px solid white" }}>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>Mgr. Ivan Weiss</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}>Mikulášska 7015/25<br></br>811 01 Bratislava</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}></td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}><br />{!lang ? t("mandatoryFrom.secondTableSecondText") : "iné bezodplatné plnenie"}</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}><br />8245,16</td>
                  <td style={{ backgroundColor: "transparent", border: "1px solid", color: "#b0b0b0"  }}><br />{!lang ? t("mandatoryFrom.secondTableThirdText") : "hodnota obvyklého úroku"}</td>
                </tr>
                
              </tbody>
            </table>
          </div>
          <p>{!lang ? t("mandatoryFrom.secondParagraph") : "Politická strana SPRAVODLIVOSŤ vyhlasuje, že s výnimkou vyššie uvedených darov a iných bezodplatných plnení, v kalendárnom roku 2023 neprijala žiadne príspevky podľa § 22 ods. 5 zákona č. 85/2005 Z.z. o politických stranách a politických hnutiach v znení neskorších právnych predpisov."}</p>
          <p>{!lang ? t("mandatoryFrom.thirdParagraph") : "Dátum zverejnenia 28.03.2024"}</p>
          {/* <div className="document"> */}
            <span id="title-2">
              <a className="document-link" href="/povinne-info/document-do">
                {!lang
                  ? t("mandatoryFrom.text")
                  : "Povinne zverejňované informácie do 1.5.2023"}
              </a>
            </span>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default DocumentOdComponent;
